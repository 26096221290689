<template>
  <div>
    <!-- 批量发送电子邮件 -->
    <el-dialog
      :title="$t('label.emailobject.groupemail')"
      :visible.sync="isBatch"
      width="80%"
      :append-to-body="true"
      custom-class="dialog-box"
      :before-close="closeDialog"
    >
      <!-- 市场云邮件评分系统 -->
      <div class="rating-email" v-show="stepIndex === 0">
        <div class="emailOption">
          <div class="emailTitle">{{ $t("c89") }}</div>
          <el-input
            size="mini"
            v-model="emailOption.emailName"
            class="emialInput"
          />
          <span style="color: #f56c6c" v-show="this.emailOption.nameCheck"
            >{{ $t("CloudCCCRM.ST.Info.TypeIn.001") }} {{ $t("c89") }}</span
          >
        </div>
        <div class="emailOption">
          <div class="emailTitle">{{ $t("c90") }}</div>
          <el-input
            size="mini"
            v-model="emailOption.emailTitle"
            class="emialInput"
            ref="titleInput"
          >
          <!-- 插入字段 -->
            <el-select
              size="mini"
              v-model="emailOption.insert"
              class="emialSelect"
              slot="append"
              :placeholder="$t('label.field.insert')"
              @change="insertInportTxt(arguments[0])"
              clearable
            >
              <el-option
                :label="$t('label.campaign.matchway.select.name')"
                :value="'{!name}'"
                style="max-width: 500px"
              >
              </el-option>
            </el-select>
          </el-input>
        </div>
        <div class="emailOption">
          <div class="emailTitle">{{ $t("vue_label_normal_campaign_sf") }}</div>
          <el-select
            size="mini"
            v-model="emailOption.emailMarket"
            class="emialInput"
            filterable
            clearable
          >
            <el-option
              v-for="item in campaignList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              style="max-width: 500px"
            >
            </el-option>
          </el-select>
        </div>
        <div class="emailOption">
          <div class="emailTitle">{{ $t("c91") }}</div>
          <el-switch
            size="mini"
            active-color="#bccad9"
            inactive-color="#f0f0f0"
            v-model="emailOption.interactionSwitch"
          />
        </div>
        <div class="emailAount" v-show="emailOption.interactionSwitch">
          <div class="emailTitle"></div>
          <div>
            <div class="emailList">
              <div class="rowOne">{{ $t("label.chatter.state") }}</div>
              <!-- 互动 -->
              <div class="rowTwo">{{ $t("c106") }}</div>
              <div class="rowThree">{{ $t("SCORE") }}</div>
              <div class="rowFour"></div>
            </div>
            <div
              class="emailList"
              @mouseover="emailOption.oneOpenFlg = true"
              @mouseleave="emailOption.oneOpenFlg = false"
            >
              <div class="rowOne">
                <el-switch
                  active-color="#bccad9"
                  inactive-color="#f0f0f0"
                  v-model="emailOption.oneOpenSwitch"
                ></el-switch>
              </div>
              <div class="rowTwo">{{ $t("c92") }}</div>
              <div class="rowThree">
                <span v-show="!emailOption.oneOpenEdit">{{
                  emailOption.oneOpenScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.oneOpenScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.oneOpenEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="emailOption.oneOpenFlg && !emailOption.oneOpenEdit"
                  @click="showEdit(0)"
                ></i>
                <div class="rowFourCheck">
                  <i
                    class="el-icon-check"
                    v-show="emailOption.oneOpenEdit"
                    @click="sureEdit(0)"
                  ></i>
                  <i
                    class="el-icon-close"
                    v-show="emailOption.oneOpenEdit"
                    @click="emailOption.oneOpenEdit = false"
                  ></i>
                </div>
              </div>
            </div>
            <div
              class="emailList"
              @mouseover="emailOption.repeatedlyOpenFlg = true"
              @mouseleave="emailOption.repeatedlyOpenFlg = false"
            >
              <div class="rowOne">
                <el-switch
                  active-color="#bccad9"
                  inactive-color="#f0f0f0"
                  v-model="emailOption.repeatedlyOpenSwitch"
                ></el-switch>
              </div>
              <div class="rowTwo">
                {{ $t("c93") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('c94')"
                  placement="top"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </div>
              <div class="rowThree">
                <span v-show="!emailOption.repeatedlyOpenEdit">{{
                  emailOption.repeatedlyOpenScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.repeatedlyOpenScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.repeatedlyOpenEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="
                    emailOption.repeatedlyOpenFlg &&
                    !emailOption.repeatedlyOpenEdit
                  "
                  @click="showEdit(1)"
                ></i>
                <div class="rowFourCheck">
                  <i
                    class="el-icon-check"
                    v-show="emailOption.repeatedlyOpenEdit"
                    @click="sureEdit(1)"
                  ></i>
                  <i
                    class="el-icon-close"
                    v-show="emailOption.repeatedlyOpenEdit"
                    @click="emailOption.repeatedlyOpenEdit = false"
                  ></i>
                </div>
              </div>
            </div>
            <!-- <div
              class="emailList"
              @mouseover="emailOption.oneClickFlg = true"
              @mouseleave="emailOption.oneClickFlg = false"
            >
              <div class="rowOne">
                <el-switch v-model="emailOption.oneClickSwitch"></el-switch>
              </div>
              <div class="rowTwo">一次点击</div>
              <div class="rowThree">
                <span v-show="!emailOption.oneClickEdit">{{
                  emailOption.oneClickScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.oneClickScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.oneClickEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="emailOption.oneClickFlg && !emailOption.oneClickEdit"
                  @click="showEdit(2)"
                ></i>
                <div class="rowFourCheck">                 
                  <i
                  class="el-icon-check"
                  v-show="emailOption.oneClickEdit"
                  @click="sureEdit(2)"
                ></i>
                 <i
                  class="el-icon-close"
                  v-show=" emailOption.oneClickEdit"
                  @click=" emailOption.oneClickEdit =false"
                ></i></div>
              </div>
            </div>
            <div
              class="emailList"
              @mouseover="emailOption.repeatedlyClickFlg = true"
              @mouseleave="emailOption.repeatedlyClickFlg = false"
            >
              <div class="rowOne">
                <el-switch
                  v-model="emailOption.repeatedlyClickSwitch"
                ></el-switch>
              </div>
              <div class="rowTwo">多次点击</div>
              <div class="rowThree">
                <span v-show="!emailOption.repeatedlyClickEdit">{{
                  emailOption.repeatedlyClickScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.repeatedlyClickScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.repeatedlyClickEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="emailOption.repeatedlyClickFlg && !emailOption.repeatedlyClickEdit"
                  @click="showEdit(3)"
                ></i>
                <div class="rowFourCheck">                 
                  <i
                  class="el-icon-check"
                  v-show="emailOption.repeatedlyClickEdit"
                  @click="sureEdit(3)"
                ></i>
                 <i
                  class="el-icon-close"
                  v-show=" emailOption.repeatedlyClickEdit"
                  @click=" emailOption.repeatedlyClickEdit =false"
                ></i></div>
              </div>
            </div> -->
            <div
              class="emailList"
              @mouseover="emailOption.oneReplyFlg = true"
              @mouseleave="emailOption.oneReplyFlg = false"
            >
              <div class="rowOne">
                <el-switch
                  active-color="#bccad9"
                  inactive-color="#f0f0f0"
                  v-model="emailOption.oneReplySwitch"
                ></el-switch>
              </div>
              <div class="rowTwo">{{ $t("c104") }}</div>
              <div class="rowThree">
                <span v-show="!emailOption.oneReplyEdit">{{
                  emailOption.oneReplyScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.oneReplyScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.oneReplyEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="emailOption.oneReplyFlg && !emailOption.oneReplyEdit"
                  @click="showEdit(4)"
                ></i>
                <div class="rowFourCheck">
                  <i
                    class="el-icon-check"
                    v-show="emailOption.oneReplyEdit"
                    @click="sureEdit(4)"
                  ></i>
                  <i
                    class="el-icon-close"
                    v-show="emailOption.oneReplyEdit"
                    @click="emailOption.oneReplyEdit = false"
                  ></i>
                </div>
              </div>
            </div>
            <div
              class="emailList"
              @mouseover="emailOption.repeatedlyReplyFlg = true"
              @mouseleave="emailOption.repeatedlyReplyFlg = false"
            >
              <div class="rowOne">
                <el-switch
                  active-color="#bccad9"
                  inactive-color="#f0f0f0"
                  v-model="emailOption.repeatedlyReplySwitch"
                ></el-switch>
              </div>
              <div class="rowTwo">
                {{ $t("c105") }}
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="$t('c94')"
                  placement="top"
                >
                  <i class="el-icon-info"></i>
                </el-tooltip>
              </div>
              <div class="rowThree">
                <span v-show="!emailOption.repeatedlyReplyEdit">{{
                  emailOption.repeatedlyReplyScore
                }}</span>
                <input
                  type="number"
                  v-model.number="emailOption.repeatedlyReplyScoreEdit"
                  class="emailNumberInput"
                  v-show="emailOption.repeatedlyReplyEdit"
                />
              </div>
              <div class="rowFour">
                <i
                  class="el-icon-edit"
                  v-show="
                    emailOption.repeatedlyReplyFlg &&
                    !emailOption.repeatedlyReplyEdit
                  "
                  @click="showEdit(5)"
                ></i>
                <div class="rowFourCheck">
                  <i
                    class="el-icon-check"
                    v-show="emailOption.repeatedlyReplyEdit"
                    @click="sureEdit(5)"
                  ></i>
                  <i
                    class="el-icon-close"
                    v-show="emailOption.repeatedlyReplyEdit"
                    @click="emailOption.repeatedlyReplyEdit = false"
                  ></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btNext">
          <el-button @click="nextEmail" type="primary" v-show="stepIndex === 0">
            {{ $t("pagecreator.button.next") }}
          </el-button>
        </div>
      </div>
      <div class="batch-email" v-show="stepIndex === 1">
        <div class="addressee">
          <!-- 搜索框 -->
          <div class="search-box" v-show="isMarketActivity">
            <!-- 搜索 -->
            <el-input
              :placeholder="$t('label.searchs')"
              suffix-icon="el-icon-search"
              @focus="contactBox"
            >
            </el-input>
          </div>
          <!-- 添加联系人弹框 -->
          <div v-if="showAddCampaignMembers">
            <addContact
              ref="addContactRef"
              :show-add-campaign-members="showAddCampaignMembers"
              @closeAddCampaignMembersDialog="closeAddCampaignMembersDialog"
              @next="next"
            >
            </addContact>
          </div>
          <!-- 收件人 -->
          {{ $t("label.emailobject.receive") }}
          ({{ userList.length }})
          <ul>
            <li v-for="(item, index) in userList" :key="index">
              <svg class="icon" aria-hidden="true" v-if="item.type == 'lead'">
                <use :href="leadImg"></use>
              </svg>
              <svg class="icon touxiang" aria-hidden="true" v-else>
                <use :href="contactImg"></use>
              </svg>
              <span class="name">{{ item.name }}</span>
              <span class="phone" v-if="item.email">{{ item.email }}</span>
              <!-- 无电子邮箱 -->
              <span class="phone active" v-if="!item.email">{{
                $t("vue_label_email_no_email")
              }}</span>
              <svg
                aria-hidden="true"
                class="icon delete"
                @click="deleteUser(index)"
              >
                <use href="#icon-chahao"></use>
              </svg>
            </li>
          </ul>
        </div>
        <div class="email-body">
          <el-input
            v-model="theme"
            :placeholder="$t('vue_label_email_enter_subject')"
          ></el-input>
          <div class="edit">
            <wangEditor
              ref="wangeditor"
              :emailInfo="emailInfo"
              :content="content"
              :inses="inse"
              @editorContent="editorContent"
            >
            </wangEditor>
          </div>
          <div class="toolbar">
            <ul>
              <li @click="tabeyescolor" :title="eyes.title">
                <div class="toolbaricon">
                  <svg class="icon" aria-hidden="true">
                    <use
                      :href="
                        eyes.istabimg == true ? eyes.changeimgurl : eyes.imgurl
                      "
                    ></use>
                  </svg>
                </div>
              </li>
              <li
                v-for="(item, index) in toolbarOption"
                :key="index"
                :title="item.title"
                @click="tabtoolbar(item.name)"
              >
                <div class="toolbaricon">
                  <svg class="icon" aria-hidden="true">
                    <use
                      :href="
                        item.istabimg == true ? item.changeimgurl : item.imgurl
                      "
                    ></use>
                  </svg>
                </div>
              </li>
            </ul>
            <!-- 添加字段 -->
            <div>
              <!-- 选择字段 -->
              <addEmailFields
                :istabimg="toolbarOption[0].istabimg"
                @closedailogFields="closedailogFields"
                @insertFieldsMethod="insertFieldsMethod"
              ></addEmailFields>
            </div>
            <!-- 管理模板 -->
            <div>
              <updateTemplate
                :istabimg="toolbarOption[1].istabimg"
                :theme="theme"
                :editorText="editorText"
                @closeTemplate="closeTemplate"
              ></updateTemplate>
            </div>
            <!-- 签名 -->
            <emailSign v-if="toolbarOption[2].istabimg"></emailSign>
          </div>
          <div class="bottom">
            <div class="related">
              <div class="title">
                <!-- 相关项 -->
                {{ $t("label.relateobj") }}
              </div>
              <relavent-select
                :isMarketActivity="isMarketActivity"
              ></relavent-select>
            </div>
            <el-button class="button" type="primary" @click="sendEmail">
              <!-- 确 定 -->
              {{ $t("label.confirm") }}
            </el-button>
            <el-button
              @click="addEmail"
              type="primary"
              class="button"
               v-show="stepIndex === 1 && (this.menuId === 'ace201754E81E522scwG' || this.menuId === 'ace20220322MarketCl')"
              style="margin-right: 10px"
            >
              {{ $t("label_tabpage_last") }}
            </el-button>
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 插入字段失败提示 -->
    <div>
      <!-- 提示 -->
      <el-dialog
        :title="$t('label.prompt')"
        :visible.sync="errdialog"
        width="40%"
      >
        <span>
          <!-- 当前邮件正文中插入的字段与已关联的记录不匹配，可能会造成取值失败。 -->
          {{ $t("label.emailobject.template.message2") }}
        </span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="errdialog = false">
            <!-- 放弃 -->
            {{ $t("label.emailobject.template.giveup") }}
          </el-button>
          <el-button type="primary" @click="Continueadd">
            <!-- 继续添加 -->
            {{ $t("label.emailobject.template.insert.continue") }}
          </el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 提示 -->
    <el-dialog
      :title="$t('label.prompt')"
      :visible.sync="isNoSelect"
      width="30%"
      :append-to-body="true"
    >
      <!-- 请选择至少一条记录作为邮件的收件人 -->
      {{ $t("label.emailobject.selectone") }}
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="hiddenT">
          <!-- 确 定 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import addContact from "@/views/noteSms/components/addContact.vue";
import addEmailFields from "@/components/Email/addEmailFields.vue";
import emailSign from "@/components/Email/emailSign";
import wangEditor from "../../components/emailEditor";
import updateTemplate from "@/components/Email/updateTemplate";
import relaventSelect from "@/components/Email/relaventSelect";
import * as Emailobject from "./api.js";

export default {
  name: "batchSendEmail",
  data() {
    return {
      // isEdit: false,
      // userNameList: [],
      campaignList: [], //市场活动
      // 统一从vuex中获取应用程序id
      menuId: this.$store.state.home.homeApplicationId,
      emailOption: {
        nameCheck: false,
        oneOpenEdit: false,
        oneClickEdit: false,
        repeatedlyClickEdit: false,
        oneReplyEdit: false,
        repeatedlyReplyEdit: false,
        repeatedlyOpenEdit: false,
        emailName: "",
        emailTitle: "",
        emailMarket: "",
        insert: "",
        interactionSwitch: true,
        oneOpenSwitch: true,
        oneClickSwitch: true,
        repeatedlyClickSwitch: true,
        oneReplySwitch: true,
        repeatedlyReplySwitch: true,
        repeatedlyOpenSwitch: true,
        oneOpenScore: 10,
        oneClickScore: 20,
        repeatedlyClickScore: 25,
        oneReplyScore: 15,
        repeatedlyReplyScore: 30,
        repeatedlyOpenScore: 20,
        oneOpenScoreEdit: 10,
        oneClickScoreEdit: 20,
        repeatedlyClickScoreEdit: 25,
        oneReplyScoreEdit: 15,
        repeatedlyReplyScoreEdit: 30,
        repeatedlyOpenScoreEdit: 20,
        oneOpenFlg: false,
        oneClickFlg: false,
        repeatedlyClickFlg: false,
        oneReplyFlg: false,
        repeatedlyReplyFlg: false,
        repeatedlyOpenFlg: false,
      },
      stepIndex: 1, //邮件步骤
      errdialog: false,
      leadImg: "#icon-touxiang",
      contactImg: "#icon-cloudtab13_norm",
      //是否显示搜索框
      isMarketActivity: false,
      // 添加联系人弹框
      showAddCampaignMembers: false,
      //插入字段
      insertFields: "",
      emailInfo: {},
      content: "", //
      inse: "",

      //关联记录ID
      relateid: "",
      //暂无选择联系人弹框
      isNoSelect: false,
      //批量发送邮件弹框
      isBatch: false,
      //发送主体内容
      editorText: "",
      //主题
      theme: "",
      userList: [],
      eyes: {
        name: "eyes",
        // 邮件追踪
        title: this.$i18n.t("label.emailobject.trackemailopening"),
        istabimg: true,
        imgurl: "#icon-show",
        changeimgurl: "#icon-show_blue",
      },
      toolbarOption: [
        {
          name: "InsertField",
          // 添加字段
          title: this.$i18n.t("label.addfield"),
          istabimg: false,
          imgurl: "#icon-insert",
          changeimgurl: "#icon-insertblue",
        },
        {
          name: "Template",
          // 选择模板
          title: this.$i18n.t("label.select.template"),
          istabimg: false,
          imgurl: "#icon-shezhi_02",
          changeimgurl: "#icon-shezhi_02_blue",
        },
        {
          name: "signature",
          // 签名
          title: this.$i18n.t("label.quote.qianming"),
          istabimg: false,
          imgurl: "#icon-editor",
          changeimgurl: "#icon-editorblue",
        },
      ],
    };
  },
  components: {
    addContact,
    updateTemplate,
    relaventSelect,
    wangEditor,
    emailSign,
    addEmailFields,
  },
  mounted() {
    this.getMarketing();
    this.$Bus.$on("deliver-change-sign", this.deliverChangeSignCallback);
    this.$Bus.$on("deliver-email-sign", this.deliverEmailSignCallback);
    this.$Bus.$on("deliver-market-activity", this.deliverMarketActivityCallback);
    this.$Bus.$on("change-sign-isSHow", this.changeSignIsSHowCallback);
    this.$Bus.$on("deliver-insert-fields",this.deliverInsertFieldsCallback);
    this.$Bus.$on("deliver-list-array", this.deliverListArrayCallback);
    this.$Bus.$on("deliver-zero-array", this.deliverZeroArrayCallback);
    this.$Bus.$on("deliver-relateid", this.deliverRelateidCallback);
    this.$Bus.$on("deliver-new-template", this.deliverNewTemplateCallback);
  },
  beforeDestroy(){
    this.$Bus.$off("deliver-change-sign", this.deliverChangeSignCallback);
    this.$Bus.$off("deliver-email-sign", this.deliverEmailSignCallback);
    this.$Bus.$off("deliver-market-activity", this.deliverMarketActivityCallback);
    this.$Bus.$off("change-sign-isSHow", this.changeSignIsSHowCallback);
    this.$Bus.$off("deliver-insert-fields",this.deliverInsertFieldsCallback);
    this.$Bus.$off("deliver-list-array", this.deliverListArrayCallback);
    this.$Bus.$off("deliver-zero-array", this.deliverZeroArrayCallback);
    this.$Bus.$off("deliver-relateid", this.deliverRelateidCallback);
    this.$Bus.$off("deliver-new-template", this.deliverNewTemplateCallback);
  },
  methods: {
    deliverChangeSignCallback(res){
      this.editorText = res;
      this.content = res;
    },
    deliverEmailSignCallback(res){
      if (res.signContent) {
        this.editorText = res.signContent;
        this.content = res.signContent;
      }
    },
    deliverMarketActivityCallback(res){
      // this.isMarketActivity = true;
      this.userList = res;

      this.isBatch = true;
    },
    changeSignIsSHowCallback(res){
      this.toolbarOption[2].istabimg = res;
    },
    deliverInsertFieldsCallback(res){
      this.insertFields = res;
    },
    deliverListArrayCallback(res){
      this.isBatch = true;
      if (this.menuId === "ace201754E81E522scwG" || this.menuId === 'ace20220322MarketCl') {
        this.stepIndex = 0;
      }
      this.getReceivePeo(res);
    },
    deliverZeroArrayCallback(){
      this.isNoSelect = true;
    },
    deliverRelateidCallback(res){
     this.relateid = res;
    },
     deliverNewTemplateCallback(res){
     this.theme = res.subject;
      this.editorText = res.content;
      this.content = res.content;
      this.toolbarOption[1].istabimg = false;
    },
    insertInportTxt(insertTxt) {
      let elInput =
        this.$refs.titleInput.$el.getElementsByClassName("el-input__inner")[0]; //获取Dom
      let startPos = elInput.selectionStart;
      let endPos = elInput.selectionEnd;
      if (startPos === undefined || endPos === undefined) return;
      let txt = elInput.value;
      let result =
        txt.substring(0, startPos) + insertTxt + txt.substring(endPos);
      elInput.value = result;
      elInput.focus();
      elInput.selectionStart = startPos + insertTxt.length;
      elInput.selectionEnd = startPos + insertTxt.length;
      this.emailOption.emailTitle = result;
    },
    sureEdit(index) {
      switch (index) {
        case 0:
          this.emailOption.oneOpenEdit = !this.emailOption.oneOpenEdit;
          this.emailOption.oneOpenScore = this.emailOption.oneOpenScoreEdit;
          break;
        case 1:
          this.emailOption.repeatedlyOpenScore =
            this.emailOption.repeatedlyOpenScoreEdit;
          this.emailOption.repeatedlyOpenEdit =
            !this.emailOption.repeatedlyOpenEdit;
          break;
        case 2:
          this.emailOption.oneClickScore = this.emailOption.oneClickScoreEdit;
          this.emailOption.oneClickEdit = !this.emailOption.oneClickEdit;
          break;
        case 3:
          this.emailOption.repeatedlyClickScore =
            this.emailOption.repeatedlyClickScoreEdit;
          this.emailOption.repeatedlyClickEdit =
            !this.emailOption.repeatedlyClickEdit;
          break;
        case 4:
          this.emailOption.oneReplyScore = this.emailOption.oneReplyScoreEdit;
          this.emailOption.oneReplyEdit = !this.emailOption.oneReplyEdit;
          break;
        case 5:
          this.emailOption.repeatedlyReplyScore =
            this.emailOption.repeatedlyReplyScoreEdit;
          this.emailOption.repeatedlyReplyEdit =
            !this.emailOption.repeatedlyReplyEdit;
          break;
        default:
          break;
      }
    },
    showEdit(index) {
      switch (index) {
        case 0:
          this.emailOption.oneOpenEdit = !this.emailOption.oneOpenEdit;
          break;
        case 1:
          this.emailOption.repeatedlyOpenEdit =
            !this.emailOption.repeatedlyOpenEdit;
          break;
        case 2:
          this.emailOption.oneClickEdit = !this.emailOption.oneClickEdit;
          break;
        case 3:
          this.emailOption.repeatedlyClickEdit =
            !this.emailOption.repeatedlyClickEdit;
          break;
        case 4:
          this.emailOption.oneReplyEdit = !this.emailOption.oneReplyEdit;
          break;
        case 5:
          this.emailOption.repeatedlyReplyEdit =
            !this.emailOption.repeatedlyReplyEdit;
          break;
        default:
          break;
      }
    },
    getMarketing() {
      Emailobject.getScoialMarketingActivity({
        orgid: this.$store.state.userInfoObj.orgId,
      }).then((res) => {
        if (res.result) {
          this.campaignList = res.data;
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
      // 无用接口，而且数据多会超时
      // Emailobject.getLookupData({
      //   fieldId: "ffe2014000032890C8YD",
      //   isKnowledge: "",
      //   isSLookup: "true",
      //   modelid: "",
      //   objId: "lead",
      //   objconid: "",
      //   prefix: "004",
      //   projectId: "",
      //   searchConditions: "",
      //   searchKeyWord: "",
      //   selectedValue: "",
      // }).then((res) => {
      //   if (res.result) {
      //     res.data.dataList.forEach((el) => {
      //       if (el.name00) {
      //         this.userNameList.push(el.name00);
      //       }
      //       if (el.name01) {
      //         this.userNameList.push(el.name01);
      //       }
      //     });
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       type: "error",
      //       message: res.returnInfo,
      //     });
      //   }
      // });
    },
    addEmail() {
      this.stepIndex = 0;
    },
    nextEmail() {
      // 市场check
      if (this.emailOption.emailName === "") {
        this.emailOption.nameCheck = true;
        return false;
      } else {
        this.emailOption.nameCheck = false;
      }
      this.stepIndex = 1;
      this.theme = this.emailOption.emailTitle;
    },
    //关闭插入模板弹窗
    closeTemplate() {
      this.toolbarOption[1].istabimg = false;
    },
    //关闭插入字段弹框
    closedailogFields() {
      this.toolbarOption[0].istabimg = false;
    },
    async getSign() {
      let result = await Emailobject.queryEmailSign({});
      if (result.result == true && result.data) {
        this.editorText = result.data.signContent;
        this.content = result.data.signContent;
      }
    },
    // 点击input框出现添加联系人弹框
    contactBox() {
      this.showAddCampaignMembers = true;
    },
    // 取消
    closeAddCampaignMembersDialog() {
      this.showAddCampaignMembers = false;
    },
    // 下一步
    next(option, type) {
      let ids = option.map((res) => res.id).join();
      let name = option.map((res) => res.name).join();
      let options = {
        ids: ids,
        name: name,
        type: type,
      };
      this.getReceivePeo(options);
      this.showAddCampaignMembers = false;
    },
    //添加字段取值失败后，继续添加字段
    Continueadd() {
      this.errdialog = false;
      let fieldstr = `<span style="color:red">{${this.insertFields}}</span>`;
      // this.editorText = this.editorText + fieldstr;
      this.$refs.wangeditor.signContent(fieldstr);
    },
    //插入字段
    async insertFieldsMethod() {
      if (this.insertFields == "") {
        //请选择插入字段
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_email_insert_field"),
        });
        return false;
      }
      if (this.relateid) {
        let result = await Emailobject.insertFields({
          filedstr: `{${this.insertFields}}`,
          recordId: this.relateid,
        });
        if (result.data == null) {
          this.errdialog = true;
        } else {
          this.$refs.wangeditor.signContent(result.data);
        }
      } else {
        this.errdialog = true;
      }
      this.toolbarOption[0].istabimg = false;
    },
    //获取收件人信息
    async getReceivePeo(option) {
      let result = await Emailobject.getReceivePeo(option);
      if (result.result == true && result.data) {
        result.data.forEach((res) => {
          if (option.type == "lead") {
            res.type = "lead";
            this.userList.push(res);
          } else {
            res.type = "contact";
            this.userList.push(res);
          }
        });
      }
    },
    editorContent(val) {
      this.editorText = val;
    },
    closeDialog() {
      this.isBatch = false
      this.theme = "";
      this.content = "";
      this.editorText = "";
      this.userList = [];
    },
    hiddenT() {
      this.isNoSelect = false;
    },
    async sendEmail() {
      if (this.userList.length == 0) {
        this.$message({
          showClose: true,
          type: "warning",
          // 请选择至少一条记录作为邮件的收件人
          message: this.$i18n.t("label.attendance.onrecord"),
        });
        return false;
      }
      if (this.theme == "") {
        //请输入邮件主题
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t("vue_label_email_please_enter_email_subject"),
        });
        return false;
      }
      if (this.editorText == "") {
        //请输入邮件内容
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$i18n.t(
            "vue_label_email_please_enter_the_message_content"
          ),
        });
        return false;
      }
      let getEmailId = this.userList.map((res) => res.email).join(";");
      let option = {};
      let result = {};
      // 邮件评分系统为1
      if (this.menuId === "ace201754E81E522scwG" || this.menuId === 'ace20220322MarketCl') {
      let  ids = ''
      this.userList.forEach((el) => {
        if (el.id) {
          ids += el.id + ",";
        }
      });
      // 去掉逗号
      if (ids.length > 0) {
        ids = ids.substr(
          0,
          ids.length - 1
        );
      }
      
        option = {
          subject: this.theme,
          toaddress: getEmailId,
          content: this.editorText,
          isattachment: "0",
          istrackopen: this.eyes.istabimg == true ? "1" : "0",
          relateid: this.relateid,
          savetemp: "0",
          singleSend: "1",
          ids:ids
        };
        option.data = {
          name: this.emailOption.emailName,
          email_subject: this.emailOption.emailTitle,
          campaign: this.emailOption.emailMarket,
          interaction_switch: this.emailOption.interactionSwitch ? "Y" : "N",
          one_open_switch: this.emailOption.oneOpenSwitch ? "Y" : "N",
          repeatedly_open_switch: this.emailOption.repeatedlyOpenSwitch
            ? "Y"
            : "N",
          one_click_switch: this.emailOption.oneClickSwitch ? "Y" : "N",
          repeatedly_click_switch: this.emailOption.repeatedlyClickSwitch
            ? "Y"
            : "N",
          one_reply_switch: this.emailOption.oneReplySwitch ? "Y" : "N",
          repeatedly_reply_switch: this.emailOption.repeatedlyReplySwitch
            ? "Y"
            : "N",
          one_open_score: this.emailOption.oneOpenScore.toString(),
          repeatedly_open_score:
            this.emailOption.repeatedlyOpenScore.toString(),
          one_click_score: this.emailOption.oneClickScore.toString(),
          repeatedly_click_score:
            this.emailOption.repeatedlyClickScore.toString(),
          one_reply_score: this.emailOption.oneReplyScore.toString(),
          repeatedly_reply_score:
            this.emailOption.repeatedlyReplyScore.toString(),
        };
        option.objectApi = "cloudccMailActivity";
        result = await Emailobject.massTextingEmail(option);
      } else {
        option = {
          subject: this.theme,
          toaddress: getEmailId,
          content: this.editorText,
          isattachment: "0",
          istrackopen: this.eyes.istabimg == true ? "1" : "0",
          relateid: this.relateid,
          savetemp: "0",
          singleSend: "0",
        };
        option.data = {};
        result = await Emailobject.sendemail(option);
      }

      if (result.result == true) {
        this.$message({
          showClose: true,
          type: "success",
          // 邮件已发送
          message: this.$i18n.t("label.emailobject.emailsent"),
        });
        if (this.menuId === "ace201754E81E522scwG" || this.menuId === 'ace20220322MarketCl') {
          this.emailOption = {
            oneOpenEdit: false,
            oneClickEdit: false,
            repeatedlyClickEdit: false,
            oneReplyEdit: false,
            repeatedlyReplyEdit: false,
            repeatedlyOpenEdit: false,
            emailName: "",
            emailTitle: "",
            emailMarket: "",
            insert: "",
            interactionSwitch: true,
            oneOpenSwitch: true,
            oneClickSwitch: true,
            repeatedlyClickSwitch: true,
            oneReplySwitch: true,
            repeatedlyReplySwitch: true,
            repeatedlyOpenSwitch: true,
            oneOpenScore: 10,
            oneClickScore: 20,
            repeatedlyClickScore: 25,
            oneReplyScore: 15,
            repeatedlyReplyScore: 30,
            repeatedlyOpenScore: 20,
            oneOpenFlg: false,
            oneClickFlg: false,
            repeatedlyClickFlg: false,
            oneReplyFlg: false,
            repeatedlyReplyFlg: false,
            repeatedlyOpenFlg: false,
          };
          this.stepIndex = 0; //邮件步骤
        }
        this.theme = "";
        this.editorText = "";
        this.userList = [];
        this.isBatch = false;
      }
    },
    tabtoolbar(name) {
      if (name == "signature") {
        this.toolbarOption[2].istabimg = true;
        this.$Bus.$emit("deliver-signature", true);
      }
      this.toolbarOption.forEach((item) => {
        //判断是否为邮件追踪项
        if (item.name == name && item.name != "eyes") {
          item.istabimg = true;
        } else {
          item.istabimg = false;
        }
        //邮件追踪项则切换选中状态
        if (item.name == "eyes") {
          item.istabimg = !item.istabimg;
        }
      });
    },
    tabeyescolor() {
      this.eyes.istabimg = !this.eyes.istabimg;
    },
    //删除用户
    deleteUser(index) {
      this.userList.splice(index, 1);
    },
  },
  watch: {
    isBatch(val) {
      if (val) {
        this.getSign();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/dialogStyle.scss";
::v-deep .dialog-box .el-dialog__body {
  padding: 0 !important;
}

.btNext {
  position: absolute;
  right: 20px;
  bottom: 12px;
  ::v-deep .el-button--primary {
    color: #fff;
    padding: 7px 15px;
    background-color: #006dcc;
    border-color: #006dcc;
  }
}
.search-box {
  width: 219px;
  height: 50px;
  padding: 11px 8px 0 0px;
  ::v-deep .el-input--suffix .el-input__inner {
    height: 28px;
  }
  ::v-deep .el-input__icon {
    line-height: 30px;
  }
}
.emailNumberInput {
  width: 33px;
  height: 13px;
}

.rating-email {
  ::v-deep .w-e-text-container {
    height: 185px !important;
  }
  height: 420px;
  padding: 20px;
  .emailOption {
    display: flex;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    .emailTitle {
      width: 200px;
      padding-right: 10px;
      height: 28px;
      line-height: 28px;
      text-align: right;
    }
    .emialInput {
      width: 300px;
      margin-right: 10px;
    }
    .emialSelect {
      width: 100px;
    }
  }
  .emailAount {
    display: flex;
    margin-bottom: 10px;
    height: 210px;
    .emailTitle {
      width: 200px;
      padding-right: 10px;
      text-align: right;
    }
    .emailList {
      display: flex;
      justify-content: space-around;
      width: 415px;
      padding: 5px;
      height: 32px;
      border: 1px solid #dcdcdc;
      .rowOne {
        width: 70px;
      }
      .rowFour {
        width: 10px;
        i {
          cursor: pointer;
        }
        .rowFourCheck {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10px;
          height: 20px;
          i {
            cursor: pointer;
            font-size: 16px;
          }
        }
      }
      .rowTwo {
        width: 140px;
      }
      .rowThree {
        width: 50px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
.batch-email {
  ::v-deep .w-e-text-container {
    height: 185px !important;
  }
  height: 420px;
  .addressee {
    padding: 20px 0 20px 37px;
    float: left;
    width: 261px;
    height: 400px;
    border-right: 1px solid #d8d8d8;
    font-weight: 600;
    overflow: auto;
    ul {
      li {
        width: 200px;
        // height: 35px;
        background: #f3f2f2;
        border-radius: 3px 3px 0px 0px;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
        padding: 10px;
        padding-right: 0;
        img {
          margin-right: 10px;
        }
        .icon {
          margin-right: 10px;
        }
        .touxiang {
          width: 20px;
          border-radius: 50%;
        }
        span {
          margin-right: 5px;
          display: inline-block;
          vertical-align: bottom;
        }
        .name {
          color: #006dcc;
          width: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .phone {
          color: #080707;
          max-width: 70px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .active {
          color: #cc0000;
        }
        .delete {
          float: right;
          cursor: pointer;
          margin-top: 3px;
        }
      }
    }
  }
  .email-body {
    float: right;
    width: calc(100% - 261px);
    height: 100%;
    padding: 10px;
    padding-right: 20px;
    ::v-deep .el-input__inner {
      height: 30px;
    }
    .edit {
      margin-top: 10px;
    }
    .toolbar {
      margin: 10px 0 0 0;
      ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0;
        li {
          border: 1px solid #dcdcdc;
          display: flex;
          align-items: center;
          padding: 5px;
          cursor: pointer;
          .toolbaricon {
            width: 15px;
            height: 15px;
            display: flex;
            align-items: center;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
    .bottom {
      .related {
        float: left;
        .title {
          margin-bottom: 3px;
        }
      }
      .button {
        margin-top: 20px;
        float: right;
        padding: 7px 15px;
        background: #006dcc;
      }
    }
  }
  .emialInputCheck {
    width: 300px;
    margin-right: 10px;
    ::v-deep .el-input__inner {
      border-color: rgb(245, 108, 108);
    }
  }
}
</style>
